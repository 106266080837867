// MARK: Implementation
export interface ILink {
	href: string;
	as: string;
}

interface IRouteType {
	[key: string]: ((...x: any) => ILink) | ILink | IRouteType;
}

function getRoutes<T extends IRouteType>(value: T): T {
	return value;
}

export const Helpers = {};

const Routes = getRoutes({
	index: (params?: string | null) => ({
		href: "/home",
		as: `/${params || ""}`,
	}),
	chats: (params?: string | null) => ({
		href: "/chats",
		as: `/${params || ""}`,
	}),
	login: (params?: string | null) => ({
		href: "/login",
		as: `/${params || ""}`,
	}),
	register: (params?: string | null) => ({
		href: "/register",
		as: `/${params || ""}`,
	}),
	favorites: (params?: string | null) => ({
		href: "/favorites",
		as: `/${params || ""}`,
	}),
	shoppingCart: (params?: string | null) => ({
		href: "/shoppingCart",
		as: `/${params || ""}`,
	}),
	profile: (params?: string | null) => ({
		href: "/profile",
		as: `/${params || ""}`,
	}),
	help: (params?: string | null) => ({
		href: "/help",
		as: `/${params || ""}`,
	}),
	contact: (params?: string | null) => ({
		href: "/contact",
		as: `/${params || ""}`,
	}),
	myStore: (params?: string | null) => ({
		href: `/storePage/${[params]}`,
		as: `/storePage/${params || ""}`,
	}),
	myEarnings: (params?: string | null) => ({
		href: "/earnings",
		as: `/${params || ""}`,
	}),
	myProducts: (params?: string | null) => ({
		href: "/myProducts",
		as: `/${params || ""}`,
	}),
	createOrEditProduct: (params?: string | null) => ({
		href: "/createOrEditProduct/create",
		as: `/${params || ""}`,
	}),
	orderHistory: (params?: string | null) => ({
		href: "/orderHistory",
		as: `/${params || ""}`,
	}),
	orderHistoryStore: (params?: string | null) => ({
		href: "/orderHistoryStore",
		as: `/${params || ""}`,
	}),
	registerStore: (params?: string | null) => ({
		href: "/registerStore",
		as: `/${params || ""}`,
	}),
	turnIntoStore: (params?: string | null) => ({
		href: "/turnIntoStore",
		as: `/${params || ""}`,
	}),
	about: (params?: string | null) => ({
		href: "/about",
		as: `/${params || ""}`,
	}),
	termsOfUse: (params?: string | null) => ({
		href: "/termsOfUse",
		as: `/${params || ""}`,
	}),
	privacyPolicy: (params?: string | null) => ({
		href: "/privacyPolicy",
		as: `/${params || ""}`,
	}),
	registerAddress: (params?: string | null) => ({
		href: "/registerAddress",
		as: `/${params || ""}`,
	}),
});

export default Routes;
