import { useState, useEffect } from "react";
import validator from "validator";

type Validation<FormFields> = [
	keyof FormFields,
	(value: FormFields[keyof FormFields]) => string | null,
];

type SetFormValues<FormFields> = (values: FormFields) => void;

interface FormEventOptions<FormFields> {
	initialValues: FormFields;
	validations?: Array<Validation<FormFields>>;
	alwaysValidate?: boolean;
	onSubmit?: (
		data: FormFields,
		setFormValues: SetFormValues<FormFields>,
	) => void;
	onSubmitError?: (errors: string[]) => void;
	// loadAsync?: (setFormValues: SetFormValues<FormFields>) => Promise<void>;
}

export interface UseFormMethods<FormFields> {
	data: FormFields;
	field: (
		key: keyof FormFields,
	) => {
		name: keyof FormFields;
		value: FormFields[keyof FormFields];
		onChange: (event: React.ChangeEvent<any>) => void;
		error: string | null;
	};
	submit: (e: any) => void;
	errors: string[];
	submited: boolean;
	isTyping: boolean;
	// loadingFormData: boolean;
	setValues: SetFormValues<FormFields>;
}

export function useInsaneForm<FormFields extends { [key: string]: any }>(
	options: FormEventOptions<FormFields>,
): UseFormMethods<FormFields> {
	const [values, setValues] = useState<FormFields>(options.initialValues);
	const [submited, setSubmited] = useState(false);
	const [submitError, setSubmitError] = useState(false);
	const [isTyping, setIsTyping] = useState(false);

	const typingDelay = 2000;
	useEffect(() => {
		const timer = setTimeout(() => {
			setIsTyping(false);
		}, typingDelay);
		return () => clearTimeout(timer);
	}, [values]);

	const setValue = (key: keyof FormFields, value: any) =>
		setValues({ ...values, [key]: value });
	const validationResult = (validation: Validation<FormFields>) =>
		validation[1](values[validation[0]]);
	const hasError = (validation: Validation<FormFields>) =>
		!!validationResult(validation);

	const allValidations = options.validations || [];
	const refusedValidations = allValidations.filter(hasError);
	const errors = refusedValidations.map(validationResult);

	const getFieldError = (key: keyof FormFields) => {
		const { validations, alwaysValidate } = options;
		if ((validations && alwaysValidate) || submitError) {
			const validation = refusedValidations.find((val) => val[0] === key);

			if (validation) {
				return validationResult(validation);
			}
		}
		return null;
	};

	const submit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (errors.length === 0 && options.onSubmit) {
			options.onSubmit(values, setValues);
			setSubmited(true);
		}
		if (errors.length > 0 && options.onSubmitError) {
			options.onSubmitError(errors);
			setSubmitError(true);
		}
	};

	const field = (key: keyof FormFields) => ({
		name: key,
		value: values[key],
		onChange: (event: React.ChangeEvent<any>) => {
			setSubmitError(false);
			setIsTyping(true);
			setValue(event.target.name as keyof FormFields, event.target.value);
		},
		error: getFieldError(key),
	});

	return {
		data: values,
		submit,
		field,
		errors,
		submited,
		isTyping,
		// loadingFormData,
		setValues,
	};
}

export const must = {
	haveHigherLengthThan: (minimum: number, message: string) => (
		text: string,
	) => (!(text.length > minimum) ? message : null),
	beValidEmail: (message: string) => (text: string) =>
		!validator.isEmail(text) ? message : null,
	beValidPhone: (message: string) => (text: string) =>
		!validator.isMobilePhone(text) ? message : null,
	fieldsMatch: (firstField: string, secondField: string) =>
		validator.equals(firstField, secondField),
	matchWith: (firstField: string, secondField: string) =>
		validator.equals(firstField, secondField)
			? null
			: "Campos não conhecidem",
};
